import React from "react"
import { Link } from "gatsby"
import Layout from '../../components/layout'
import { StaticImage } from "gatsby-plugin-image"




const IncusIntlPresMay2023 = ({ data }) => {
    return (
        <Layout pageTitle="INCUS | INCUS Presented at International Conferences">
            <h1>INCUS Presented at International Conferences</h1>
            <StaticImage
                alt="Benjamin Donitz, an Advanced Design Systems Engineer at NASA’s Jet Propulsion Laboratory, gives a presentation on INCUS at the COmmittee on SPAce Research (COSPAR) 2023 conference on small satellites"
                src="./images/benjamin_donitz_cospar.jpg"
                layout="constrained"
                placeholder="blurred"
                height={300}
                objectFit="contain"
                transformOptions={{ fit: "cover" }}
            />
            <h5>April 28, 2023</h5>

            <p>VIENNA, AUSTRIA and SINGAPORE- Several members of the INCUS <Link to="../mission/team">Science Team</Link> recently gave international presentations on the current developments in the INCUS mission. Susan van den Heever, PI of the INCUS mission, presented on INCUS both at the 2023 European Geophysical Union (EGU) meeting in Vienna and virtually to the European collaboration on the Next Generation Earth Modeling Systems (NextGEMS). Ziad Haddad, Deputy PI of the INCUS mission, also presented at EGU in Vienna on the developments occurring in the INCUS algorithm, with a second presentation at the NASA Hyperwall. Sean Freeman, a member of the science team, also presented on the development of cloud tracking datasets relevant for INCUS at EGU in Vienna.

            </p>

            <p>
                On the engineering side, Benjamin Donitz, an Advanced Design Systems Engineer at NASA’s Jet Propulsion Laboratory, presented on the INCUS mission architecture and Systems Engineering Approach. Benjamin presented these results at the 5th COmmittee on SPAce Research (COSPAR) Symposium on Space Science with Small Satellites at Nanyang Technological University in Singapore.
            </p>
            <p>
                Citations: <br />
                van den Heever, S.C., and co-authors, 2023: The INCUS Mission. Seminar, NextGEMS Storms and Science Hour, 5 April 2023, Virtual.
                <br />
                van den Heever, S.C., and co-authors, 2023: Tropical Convection through the Lens of the INCUS Mission. European Geophysical Union General Assembly, 26 April 2023, In-Person
                <br />
                Haddad, Z., S. Prasanth, S.C. van den Heever, P. Marinescu, S. Freeman, and D. Posselt, 2023: Parametrizing the evolution of convective updraft vertical velocities. European Geophysical Union General Assembly, 26 April 2023, In-Person
                <br />
                Freeman, S.W., R. Schulte, G. Leung, S.C. van den Heever, 2023: Tracking Convective Storms and their Environments with the tobac Tracking Package. European Geophysical Union General Assembly, 26 April 2023, In-Person
                <br />
                Donitz, Benjamin, et. al. (April 16 – 21, 2023). INCUS Mission Architecture and Systems Engineering Approach. COSPAR 2023, Singapore.
            </p>


        </Layout >

    )
}

export default IncusIntlPresMay2023

